function cvForm () {
  $(document).on('selectizechange', '#hdyk_select', (e) => {
    const target = e.currentTarget
    
    if (target.value.toLowerCase() === 'інше' || target.value.toLowerCase() === 'other') {
      $('#hdyk_input').slideDown(300)
    } else {
      $('#hdyk_input').slideUp(300).promise().done(() => {
        $('#hdyk_input input').val('')
      })
    }
  })
  
  $(document).on('selectizechange', '#haus_select', (e) => {
    const target = e.currentTarget
    
    if (target.value.toLowerCase() === 'yes' || target.value.toLowerCase() === 'так') {
      $('#haus_input').slideDown(300)
    } else {
      $('#haus_input').slideUp(300).promise().done(() => {
        $('#haus_input input').val('')
      })
    }
  })
}

export { cvForm }