export default class CFPlate {

  constructor (el) {
    this.el = el
    this.trigger = document.querySelector('#vacancies')
    this.getInTouch = document.querySelector('.get-in-touch')

    this.handler = (e) => this.checkVisibility(e)
  }

  init () {
    this.initEvents()
    this.checkVisibility()
  }

  destroy () {
    this.removeEvents()
    this.getInTouch.classList.remove('shift-top')
  }

  checkVisibility () {
    if (this.trigger.getBoundingClientRect().top < window.innerHeight && this.trigger.getBoundingClientRect().bottom > 0) {
      this.el.classList.remove('active')
      this.getInTouch.classList.remove('shift-top')
    } else {
      this.el.classList.add('active')
      this.getInTouch.classList.add('shift-top')
    }
  }

  initEvents () {
    window.addEventListener('scroll', this.handler)
    window.addEventListener('resize', this.handler)
  }

  removeEvents () {
    window.removeEventListener('scroll', this.handler)
    window.removeEventListener('resize', this.handler)
  }

}
